/* eslint consistent-return: off */
/* eslint jsx-a11y/label-has-associated-control: off */
/* eslint prefer-destructuring: off */
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IoIosCreate } from 'react-icons/io';
import axios from 'axios';
import { useStateContext } from '../contexts/ContextProvider';
import { Button, Alert } from '../components';

const Create = ({ isWIP }) => {
  const state = useLocation().state;
  const [title, setTitle] = useState(state?.title || '');
  const [cover, setCover] = useState(null);
  const [coverIn, setCoverIn] = useState(null);
  const [images, setImages] = useState([]);
  const [progress1, setProgress1] = useState();
  const [progress2, setProgress2] = useState();
  const [progress3, setProgress3] = useState();
  const [msgAlert, setMsgAlert] = useState({ color: '', message: '' });
  const [showAlert, setShowAlert] = useState(false);

  const navigate = useNavigate();

  // eslint-disable-next-line no-unused-vars
  const { currentColor, currentMode } = useStateContext();

  const upload = async (file) => {
    try {
      const formData = new FormData();
      formData.append('coverFile', file);
      const res = await axios.post('/api/upload/single', formData, {
        onUploadProgress: (data) => {
          setProgress1(Math.round((100 * data.loaded) / data.total));
        },
      });
      return res.data;
    } catch (err) {
      // console.log(err);
      setShowAlert(true);
      setMsgAlert({ color: 'red', message: { err } });
    }
  };
  const uploadIn = async (file) => {
    try {
      const formData = new FormData();
      formData.append('coverInFile', file);
      const res = await axios.post('/api/upload/singleIn', formData, {
        onUploadProgress: (data) => {
          setProgress2(Math.round((100 * data.loaded) / data.total));
        },
      });
      return res.data;
    } catch (err) {
      // console.log(err);
      setShowAlert(true);
      setMsgAlert({ color: 'red', message: { err } });
    }
  };

  const uploadImg = async (files) => {
    try {
      const formData = new FormData();
      Object.values(files).forEach((image) => {
        formData.append('imgFiles', image);
      });
      const res = await axios.post('/api/upload/multi', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (data) => {
          setProgress3(Math.round((100 * data.loaded) / data.total));
        },
      });
      return res.data;
    } catch (err) {
      // console.log(err);
      setShowAlert(true);
      setMsgAlert({ color: 'red', message: { err } });
    }
  };

  const handleClick = async (e) => {
    e.preventDefault();
    // const [coverUrl, coverInUrl, imagesUrl] = '';
    const coverUrl = await upload(cover);
    const coverInUrl = await uploadIn(coverIn);
    // console.log('single');
    // console.log(coverUrl);

    // console.log('multi');
    // console.log(imagesUrl);
    const imagesUrl = await uploadImg(images);
    try {
      // console.log('try catch process');
      await axios.post(isWIP ? '/api/wip/' : '/api/posts/', {
        title,
        img: cover ? coverUrl : '',
        imgIn: coverIn ? coverInUrl : '',
        picture: images ? imagesUrl : [],
      });
      // console.log(res);
      setProgress1(false);
      setProgress2(false);
      setProgress3(false);
      navigate('/');
    } catch (err) {
      // console.log(err);
      setShowAlert(true);
      setMsgAlert({ color: 'red', message: { err } });
    }
    // try {
    //   state
    //     ? await axios.put(`/posts/${state.id}`, {
    //         title,
    //         img: cover ? coverUrl : '',
    //       })
    //     : await axios.post(`/posts/`, {
    //         title,
    //         img: cover ? coverUrl : '',
    //       });
    //   navigate('/');
    // } catch (err) {
    //   console.log(err);
    // }
  };

  return (
    <div className="">
      {showAlert && (
        <Alert
          color={msgAlert.color}
          message={msgAlert.message}
          setOpenAlert={setShowAlert}
        />
      )}
      <section className="overflow-hidden text-gray-700">
        <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
          {progress1 && (
            <div className="w-full bg-gray-200 rounded-full h-6 dark:bg-gray-700 mb-2 relative">
              <div className="absolute w-full text-center text-white">
                ภาพปกนอก
              </div>
              <div
                className="h-6 rounded-full"
                style={{
                  width: `${progress1}%`,
                  backgroundColor: currentColor,
                }}
              />
            </div>
          )}
          {progress2 && (
            <div className="w-full bg-gray-200 rounded-full h-6 dark:bg-gray-700 mb-2 relative">
              <div className="absolute w-full text-center text-white">
                ภาพปกใน
              </div>
              <div
                className="h-6 rounded-full"
                style={{
                  width: `${progress2}%`,
                  backgroundColor: currentColor,
                }}
              />
            </div>
          )}
          {progress3 && (
            <div className="w-full bg-gray-200 rounded-full h-6 dark:bg-gray-700 mb-2 relative">
              <div className="absolute w-full text-center text-white">
                ภาพประกอบ
              </div>
              <div
                className="h-6 rounded-full"
                style={{
                  width: `${progress3}%`,
                  backgroundColor: currentColor,
                }}
              />
            </div>
          )}
          <div className="mb-6 flex justify-between items-center">
            <h1 className="font-semibold text-2xl">Create Post</h1>
            <div className="flex flex-row-reverse">
              <div className="mx-1" onClick={handleClick}>
                <Button
                  icon={<IoIosCreate className="mr-1" />}
                  color="white"
                  bgColor="#28a745"
                  text={<span>Create</span>}
                  borderRadius="10px"
                />
              </div>
              {/* <div className="mx-1" >
                <Button
                  icon={<IoIosCreate className="mr-1" />}
                  color="white"
                  bgColor={currentColor}
                  text={<span>Cover</span>}
                  borderRadius="10px"
                />
              </div>
              <label className="mx-1">
                <Button
                  icon={<IoIosCreate className="mr-1" />}
                  color="white"
                  bgColor={currentColor}
                  text={<span>Image</span>}
                  borderRadius="10px"
                />
              </label> */}
            </div>
          </div>
          <div className="flex flex-wrap -m-1 md:-m-2">
            <div className="flex flex-wrap w-full">
              <div className="flex items-center mb-5 w-full">
                <label
                  htmlFor="title"
                  className="w-20 inline-block text-right mr-4 text-gray-500"
                >
                  ชื่อโปรเจค
                </label>
                <input
                  name="title"
                  id="title"
                  type="text"
                  placeholder="Enter Title"
                  className="border-b-2 flex-1 py-2 placeholder-gray-300 outline-none focus:border-green-400"
                  onChange={(e) => setTitle(e.target.value)}
                  required
                />
              </div>
              <div className="flex justify-center w-full">
                <div className="mb-3 flex items-center">
                  <label
                    htmlFor="coverFile"
                    className="form-label inline-block w-28 text-right pr-1 text-gray-500"
                  >
                    ภาพปกนอก
                  </label>
                  <input
                    className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    type="file"
                    name="coverFile"
                    id="coverFile"
                    accept=".jpeg,.jpg,.png"
                    onChange={(e) => setCover(e.target.files[0])}
                    required
                  />
                </div>
              </div>
              <div className="flex justify-center w-full">
                <div className="mb-3 flex items-center">
                  <label
                    htmlFor="coverInFile"
                    className="form-label inline-block w-28 text-right pr-1 text-gray-500"
                  >
                    ภาพปกใน
                  </label>
                  <input
                    className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    type="file"
                    name="coverInFile"
                    id="coverInFile"
                    accept=".jpeg,.jpg,.png"
                    onChange={(e) => setCoverIn(e.target.files[0])}
                    required
                  />
                </div>
              </div>
              <div className="flex justify-center w-full">
                <div className="mb-3 flex items-center">
                  <label
                    htmlFor="imgFiles"
                    className="form-label inline-block w-28 text-right pr-1 text-gray-500"
                  >
                    ภาพประกอบ
                  </label>
                  <input
                    className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    type="file"
                    name="imgFiles"
                    id="imgFiles"
                    accept=".jpeg,.jpg,.png"
                    multiple
                    onChange={(e) => setImages(e.target.files)}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-wrap w-1/3">
              <div className="w-full p-1 md:p-2">
                <img
                  alt="gallery"
                  className="block object-cover object-center w-full h-full rounded-lg"
                  src="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(73).webp"
                />
              </div>
            </div>
            <div className="flex flex-wrap w-1/3">
              <div className="w-full p-1 md:p-2">
                <img
                  alt="gallery"
                  className="block object-cover object-center w-full h-full rounded-lg"
                  src="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(74).webp"
                />
              </div>
            </div>
            <div className="flex flex-wrap w-1/3">
              <div className="w-full p-1 md:p-2">
                <img
                  alt="gallery"
                  className="block object-cover object-center w-full h-full rounded-lg"
                  src="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(75).webp"
                />
              </div>
            </div>
            <div className="flex flex-wrap w-1/3">
              <div className="w-full p-1 md:p-2">
                <img
                  alt="gallery"
                  className="block object-cover object-center w-full h-full rounded-lg"
                  src="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(70).webp"
                />
              </div>
            </div>
            <div className="flex flex-wrap w-1/3">
              <div className="w-full p-1 md:p-2">
                <img
                  alt="gallery"
                  className="block object-cover object-center w-full h-full rounded-lg"
                  src="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(76).webp"
                />
              </div>
            </div>
            <div className="flex flex-wrap w-1/3">
              <div className="w-full p-1 md:p-2">
                <img
                  alt="gallery"
                  className="block object-cover object-center w-full h-full rounded-lg"
                  src="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(72).webp"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Create;
