/* eslint-disable */
import { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import Cookies from 'js-cookie';

const PrivateRoute = () => {
  // const { currentUser } = useContext(AuthContext);
  const { currentUser, login, logout } = useContext(AuthContext);
  const token = Cookies.get('access_token');
  const checkUser = !token || currentUser === undefined || currentUser === null;

  /* console.log("token");
  console.log(token);
  console.log("checkUser");
  console.log(checkUser);
  console.log("currentUser");
  console.log(currentUser); */
  return checkUser ? <Navigate to="/login" /> : <Outlet />;
};

export default PrivateRoute;
