import React from 'react';

export default function Alert({ color, message, setOpenAlert }) {
  const alertClass = `text-white px-6 py-4 border-0 rounded fixed z-10 mb-4 left-[50%] bg-${color}-500`;
  const colorMap = {
    red: 'rgb(239, 68, 68)',
    green: 'rgb(71, 199, 153)',
  };

  const alertStyle = {
    background: colorMap[color],
  };
  return (
    <div
      className={alertClass}
      style={alertStyle}
    >
      <span className="text-xl inline-block mr-5 align-middle">
        <i className="fas fa-bell" />
      </span>
      <span className="inline-block align-middle mr-8">
        <b className="capitalize">{message}</b>
      </span>
      <button
        className="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none"
        type="button"
        onClick={() => setOpenAlert(false)}
      >
        <span>×</span>
      </button>
    </div>
  );
}
