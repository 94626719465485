/* eslint no-unused-vars: off */
/* eslint no-shadow: off */
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useStateContext } from '../contexts/ContextProvider';
import { Button } from '../components';

const Register = () => {
  const [inputs, setInputs] = useState({
    username: '',
    email: '',
    password: '',
  });
  const [err, setError] = useState(null);

  const navigate = useNavigate();
  const { currentColor, currentMode } = useStateContext();

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post('/auth/register', inputs);
      console.log(res);
      // navigate('/login');
    } catch (err) {
      setError(err.response.data);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-sky-200">
      <form className="flex flex-col p-12 bg-white dark:bg-secondary-dark-bg w-200 gap-5">
        <h1
          className="text-xl mb-5 font-bold text-center"
          style={{ color: currentColor }}
        >
          Register
        </h1>
        <input
          required
          className="p-2.5 border-b border-gray-400 focus-visible:dark:outline-none focus-visible:dark:rounded-sm"
          style={{
            outlineColor: currentColor,
            outlineOffset: '0',
            caretColor: currentColor,
          }}
          type="text"
          placeholder="username"
          name="username"
          onChange={handleChange}
        />
        <input
          required
          className="p-2.5 border-b border-gray-400 focus-visible:dark:outline-none focus-visible:dark:rounded-sm"
          style={{
            outlineColor: currentColor,
            outlineOffset: '0',
            caretColor: currentColor,
          }}
          type="text"
          placeholder="name"
          name="name"
          onChange={handleChange}
        />
        <input
          className="p-2.5 border-b border-gray-400 focus-visible:dark:outline-none focus-visible:dark:rounded-sm"
          style={{
            outlineColor: currentColor,
            outlineOffset: '0',
            caretColor: currentColor,
          }}
          type="email"
          placeholder="email"
          name="email"
          onChange={handleChange}
        />
        <input
          required
          className="p-2.5 border-b border-gray-400 focus-visible:dark:outline-none focus-visible:dark:rounded-sm"
          style={{
            outlineColor: currentColor,
            outlineOffset: '0',
            caretColor: currentColor,
          }}
          type="password"
          placeholder="password"
          name="password"
          onChange={handleChange}
        />
        <div onClick={handleSubmit}>
          <Button
            color="white"
            bgColor={currentColor}
            text="Register"
            borderRadius="10px"
            width="full"
          />
        </div>
        {err && <p className="text-xs text-red-500 text-center">{err}</p>}
        <span className="text-xs text-center dark:text-white">
          Do you have an account? <Link to="/login">Login</Link>
        </span>
      </form>
    </div>
  );
};

export default Register;
