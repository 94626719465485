/* eslint-disable */
import axios from 'axios';
import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { useStateContext } from '../contexts/ContextProvider';
import { Button } from '../components';

const Login = () => {
  const [inputs, setInputs] = useState({
    username: '',
    password: '',
  });
  const [err, setError] = useState(null);

  const navigate = useNavigate();

  const { login } = useContext(AuthContext);
  const { currentColor, currentMode } = useStateContext();

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(inputs);
      navigate('/');
    } catch (err) {
      setError(err.response.data);
      console.log(err)
    }
  };
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-sky-200">
      <form className="flex flex-col p-12 bg-white dark:bg-secondary-dark-bg w-200 gap-5">
        <h1
          className="text-xl mb-5 font-bold text-center"
          style={{ color: currentColor }}
        >
          Login
        </h1>
        <input
          required
          className="p-2.5 border-b border-gray-400 focus-visible:dark:outline-none focus-visible:dark:rounded-sm"
          style={{
            outlineColor: currentColor,
            outlineOffset: '0',
            caretColor: currentColor,
          }}
          type="text"
          placeholder="username"
          name="username"
          onChange={handleChange}
        />
        <input
          required
          className="p-2.5 border-b border-gray-400 focus-visible:dark:outline-none focus-visible:dark:rounded-sm"
          style={{
            outlineColor: currentColor,
            outlineOffset: '0',
            caretColor: currentColor,
          }}
          type="password"
          placeholder="password"
          name="password"
          onChange={handleChange}
        />
        <div onClick={handleSubmit}>
          <Button
            color="white"
            bgColor={currentColor}
            text="Login"
            borderRadius="10px"
            width="full"
          />
        </div>
        {err && <p className="text-xs text-red-500 text-center">{err}</p>}
        <span className="text-xs text-center dark:text-white">
          Don't you have an account? <Link to="/register">Register</Link>
        </span>
      </form>
    </div>
  );
};

export default Login;
