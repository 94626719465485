/* eslint consistent-return: off */
/* eslint jsx-a11y/label-has-associated-control: off */
/* eslint prefer-destructuring: off */
/* eslint no-unused-vars: off */
/* eslint arrow-body-style: off */
/* eslint no-console: off */
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { arrayMoveImmutable } from 'array-move';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { MdPostAdd } from 'react-icons/md';
import { IoIosCreate } from 'react-icons/io';
import { TiDelete } from 'react-icons/ti';
import axios from 'axios';

import { useStateContext } from '../contexts/ContextProvider';
import { Button, Alert } from '../components';

const Posts = ({ post, title, img, bgColor, wip }) => {
  return (
    <div className="flex flex-wrap w-1/1 lg:w-1/3 md:w-1/2 sm:w-1/1 ">
      <Link to={wip ? `/wip/${post}` : `/post/${post}`} className="w-full">
        <div className="inline-block overflow-hidden w-full p-1">
          <div className="relative flex overflow-hidden justify-center items-center w-full h-[250px] z-[1] rounded-lg cursor-pointer">
            <img
              className="min-w-full min-h-full object-cover z-[1]"
              src={img}
            />
            <div className="absolute w-full h-full top-0 flex items-start justify-end flex-col text-white pb-[10] pl-[10] transition z-[2] hover:bg-gradient-to-t from-black/50 to-transparent">
              <div className="w-full" style={{ backgroundColor: bgColor }}>
                <span className="text-lg font-bold m-2 block">{title}</span>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

const SortablePosts = SortableElement(({ value, currentColor, isWip }) => (
  <Posts
    post={value.post}
    title={value.title}
    img={value.img}
    bgColor={currentColor}
    wip={isWip}
  />
));
const SortableContain = SortableContainer(({ items, currentColor, isWip }) => {
  // console.log('in sort');
  // console.log(items);
  return (
    <div className="flex flex-wrap -m-1 md:-m-2">
      {items.map((value, index) => (
        <SortablePosts
          key={`post-${index}`}
          index={index}
          value={value}
          currentColor={currentColor}
          isWip={isWip}
        />
      ))}
    </div>
  );
});

const Dashboard = ({ isWIP }) => {
  // eslint-disable-next-line no-unused-vars
  const { currentColor, currentMode } = useStateContext();
  const navigate = useNavigate();
  const location = useLocation();
  // const rows = [];
  // console.log("begin for");
  // console.log(rows);
  // for (let index = 1; index <= 30; index += 1) {
  //   rows.push({
  //     post: `${index}`,
  //     title: `งานปรับปรุงและต่อเติมบ้านพุทธมณฑลสาย ${index}`,
  //     img: `https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(${index}).webp`,
  //   });
  // }
  // console.log("end for");
  // console.log(rows);
  const [post, setPost] = useState(null);
  const [topic, setTopic] = useState([]);
  const [progress, setProgress] = useState();
  const [msgAlert, setMsgAlert] = useState({ color: '', message: '' });
  const [showAlert, setShowAlert] = useState(false);

  const getData = async () => {
    const res = await axios.get(isWIP ? '/api/wip' : '/api/posts');
    setPost(res.data);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setTopic(arrayMoveImmutable(topic, oldIndex, newIndex));
  };

  useEffect(() => {
    getData();
  }, [location.pathname]);

  useEffect(() => {
    if (post) {
      setTopic(post);
      // console.log('Axios Complete:' + postId);
      console.log('post');
      console.log(post);
      console.log('topic');
      console.log(topic);
      // console.log(title);
    }
  }, [post]);

  const updateClick = async (e) => {
    e.preventDefault();
    try {
      await axios.put(isWIP ? '/api/wip' : '/api/posts', {
        topic,
      });
      // navigate('/');
      setShowAlert(true);
      setMsgAlert({ color: 'green', message: 'Success,Post layout updated.' });
      // setShowAlert(true);
      //   setMsgAlert({ color: 'red', message: { err } });
    } catch (err) {
      // console.log(err);
      setShowAlert(true);
      setMsgAlert({ color: 'red', message: { err } });
    }
  };

  // const handleDelete = async (idPost) => {
  //   try {
  //     await axios.delete(`/posts/${idPost}`);
  //     navigate('/');
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  return (
    <div className="">
      {showAlert && (
        <Alert
          color={msgAlert.color}
          message={msgAlert.message}
          setOpenAlert={setShowAlert}
        />
      )}
      <section className="overflow-hidden text-gray-700">
        <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
          <div className="mb-6 flex justify-between items-center">
            <h1 className="font-semibold text-2xl">
              {isWIP ? 'WIP' : 'Posts'}
            </h1>
            <div className="flex">
              <div onClick={updateClick} className="mr-2">
                <Button
                  icon={<IoIosCreate className="mr-1" />}
                  color="white"
                  bgColor="#28a745"
                  text={<span>Update Post</span>}
                  borderRadius="10px"
                />
              </div>
              <Link to={isWIP ? '/wcreate' : '/create'}>
                <Button
                  icon={<MdPostAdd className="mr-1" />}
                  color="white"
                  bgColor={currentColor}
                  text={<span>Create Post</span>}
                  borderRadius="10px"
                />
              </Link>
            </div>
          </div>
          {progress && (
            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
              <div
                className="h-2.5 rounded-full"
                style={{ width: `${progress}%`, backgroundColor: currentColor }}
              />
            </div>
          )}
          {topic.length > 0 && (
            <SortableContain
              items={topic}
              currentColor={currentColor}
              onSortEnd={onSortEnd}
              axis="xy"
              distance={1}
              isWip={isWIP}
            />
          )}
        </div>
      </section>
    </div>
  );
};

export default Dashboard;
