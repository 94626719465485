/* eslint consistent-return: off */
/* eslint jsx-a11y/label-has-associated-control: off */
/* eslint prefer-destructuring: off */
/* eslint no-unused-vars: off */
/* eslint react/jsx-props-no-spreading: off */
/* eslint no-else-return: off */
/* eslint object-shorthand: off */
/* eslint jsx-a11y/no-noninteractive-element-interactions: off */
/* eslint arrow-body-style: off */
import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Gallery from 'react-photo-gallery';
import { arrayMoveImmutable } from 'array-move';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import axios from 'axios';
import { IoIosCreate, IoIosAlert, IoMdTrash } from 'react-icons/io';
import { AiFillEdit, AiFillSave, AiOutlineUpload } from 'react-icons/ai';
import { useStateContext } from '../contexts/ContextProvider';
import { Button, Modal, Alert } from '../components';

const imgWithClick = { cursor: 'pointer' };

const Photo = ({ index, onClick, photo, margin, direction, top, left }) => {
  const imgStyle = { margin: margin };
  if (direction === 'column') {
    imgStyle.position = 'absolute';
    imgStyle.left = left;
    imgStyle.top = top;
  }

  const handleClick = (event) => {
    onClick(event, { photo, index });
  };

  return (
    <img
      style={onClick ? { ...imgStyle, ...imgWithClick } : imgStyle}
      {...photo}
      onClick={onClick ? handleClick : null}
      alt="img"
    />
  );
};

const SortablePhoto = SortableElement((item) => <Photo {...item} />);
const SortableGallery = SortableContainer(({ items }) => (
  <Gallery
    photos={items}
    renderImage={(props) => <SortablePhoto {...props} />}
  />
));

const Read = ({ isWIP }) => {
  // eslint-disable-next-line no-unused-vars
  const { currentColor, currentMode } = useStateContext();
  const navigate = useNavigate();
  const [imgArr, setImgArr] = useState([]);
  const [title, setTitle] = useState('');
  const [oldTitle, setOldTitle] = useState('');
  const [cover, setCover] = useState(null);
  const [coverIn, setCoverIn] = useState(null);
  const [images, setImages] = useState([]);
  const [post, setPost] = useState(null);
  const [msgAlert, setMsgAlert] = useState({ color: '', message: '' });
  const [showAlert, setShowAlert] = useState(false);
  const [progress1, setProgress1] = useState();
  const [progress2, setProgress2] = useState();
  const [progress3, setProgress3] = useState();
  const [showModal, setShowModal] = useState(false);

  const location = useLocation();
  const postId = location.pathname.split('/')[2];

  const getData = async () => {
    const res = await axios.get(
      isWIP ? `/api/wip/${postId}` : `/api/posts/${postId}`,
    );
    setPost(res.data);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setImgArr(arrayMoveImmutable(imgArr, oldIndex, newIndex));
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (post) {
      setTitle(post.post);
      setOldTitle(post.post);
      setImgArr(post.images);
      // console.log('Axios Complete:' + postId);
      // console.log(post);
      // console.log(title);
    }
  }, [post]);

  const upload = async () => {
    try {
      const formData = new FormData();
      formData.append('coverFile', cover);
      const res = await axios.post('/api/upload/single', formData, {
        onUploadProgress: (data) => {
          setProgress1(Math.round((100 * data.loaded) / data.total));
        },
      });
      return res.data;
    } catch (err) {
      // console.log(err);
    }
  };
  const uploadIn = async () => {
    try {
      const formData = new FormData();
      formData.append('coverInFile', coverIn);
      const res = await axios.post('/api/upload/singleIn', formData, {
        onUploadProgress: (data) => {
          setProgress2(Math.round((100 * data.loaded) / data.total));
        },
      });
      return res.data;
    } catch (err) {
      // console.log(err);
    }
  };

  const uploadImg = async () => {
    try {
      const formData = new FormData();
      Object.values(images).forEach((image) => {
        formData.append('imgFiles', image);
      });
      const res = await axios.post('/api/upload/multi', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (data) => {
          setProgress3(Math.round((100 * data.loaded) / data.total));
        },
      });
      return res.data;
    } catch (err) {
      // console.log(err);
    }
  };

  const updateTitle = async (e) => {
    e.preventDefault();
    if (title && title.trim() !== '' && title !== oldTitle) {
      try {
        await axios.post(isWIP ? '/api/wip/title/' : '/api/posts/title/', {
          id: postId,
          title: title,
        });
        // Alert('green', 'Success,Post layout updated.');
        // console.log('success');
        setShowAlert(true);
        setMsgAlert({
          color: 'green',
          message: 'Success,Title updated.',
        });
        setOldTitle(title);
        // window.location.reload(false);
      } catch (err) {
        // console.log(err);
        setShowAlert(true);
        setMsgAlert({ color: 'red', message: { err } });
      }
    } else {
      // console.log('Cover Ext.');
      setShowAlert(true);
      setMsgAlert({ color: 'red', message: 'Project Title cannot empty or has same value for save' });
    }
  };

  const updateCover = async (e) => {
    e.preventDefault();
    if (cover) {
      const coverUrl = await upload(cover);
      // console.log(coverUrl);
      try {
        await axios.post(isWIP ? '/api/wip/cover/' : '/api/posts/cover/', {
          id: postId,
          img: coverUrl,
        });
        // Alert('green', 'Success,Post layout updated.');
        // console.log('success');
        setShowAlert(true);
        setMsgAlert({
          color: 'green',
          message: 'Success,Cover updated.',
        });
        setProgress1(false);
        window.location.reload(false);
        // navigate('/');
      } catch (err) {
        // console.log(err);
        setShowAlert(true);
        setMsgAlert({ color: 'red', message: { err } });
      }
    } else {
      // console.log('Cover Ext.');
      setShowAlert(true);
      setMsgAlert({ color: 'red', message: 'Please select file for upload' });
    }
  };

  const updateCoverIn = async (e) => {
    e.preventDefault();
    if (coverIn) {
      const coverInUrl = await uploadIn(coverIn);
      // console.log(coverInUrl);
      try {
        await axios.post(isWIP ? '/api/wip/coverIn/' : '/api/posts/coverIn/', {
          id: postId,
          imgIn: coverInUrl,
        });
        // Alert('green', 'Success,Post layout updated.');
        setShowAlert(true);
        setMsgAlert({
          color: 'green',
          message: 'Success,CoverIn updated.',
        });
        setProgress2(false);
        window.location.reload(false);
        // navigate('/');
      } catch (err) {
        // console.log(err);
        setShowAlert(true);
        setMsgAlert({ color: 'red', message: { err } });
      }
    } else {
      // console.log('CoverIn Ext.');
      setShowAlert(true);
      setMsgAlert({ color: 'red', message: 'Please select file for upload' });
    }
  };

  const updateImageExt = async (e) => {
    e.preventDefault();
    if (images.length > 0) {
      const imagesUrl = await uploadImg(images);
      // console.log(imagesUrl);
      try {
        await axios.post(isWIP ? '/api/wip/ext/' : '/api/posts/ext/', {
          id: postId,
          picture: imagesUrl,
        });
        // Alert('green', 'Success,Post layout updated.');
        setShowAlert(true);
        setMsgAlert({
          color: 'green',
          message: 'Success,Cover picture updated.',
        });
        setProgress3(false);
        window.location.reload(false);
        // navigate('/');
      } catch (err) {
        // console.log(err);
        setShowAlert(true);
        setMsgAlert({ color: 'red', message: { err } });
      }
    } else {
      // console.log('Images Ext.');
      setShowAlert(true);
      setMsgAlert({ color: 'red', message: 'Please select file for upload' });
    }
  };

  const updateClick = async (e) => {
    e.preventDefault();
    try {
      await axios.put(isWIP ? `/api/wip/${postId}` : `/api/posts/${postId}`, {
        picture: imgArr,
      });
      // Alert('green', 'Success,Post layout updated.');
      setShowAlert(true);
      setMsgAlert({ color: 'green', message: 'Success,Post layout updated.' });
      // navigate('/');
    } catch (err) {
      // console.log(err);
      setShowAlert(true);
      setMsgAlert({ color: 'red', message: { err } });
    }
  };

  const deleteClick = async (e) => {
    e.preventDefault();
    try {
      await axios.delete(isWIP ? `/api/wip/${postId}` : `/api/posts/${postId}`);
      // Alert('green', 'Success,Post layout updated.');
      setShowAlert(true);
      setMsgAlert({ color: 'green', message: 'Success,Post layout updated.' });
      navigate('/');
    } catch (err) {
      // console.log(err);
      setShowAlert(true);
      setMsgAlert({ color: 'red', message: { err } });
    }
  };

  if (post) {
    return (
      <div className="">
        {showAlert && (
          <Alert
            message={msgAlert.message}
            color={msgAlert.color}
            setOpenAlert={setShowAlert}
          />
        )}
        {showModal && (
          <Modal
            setOpenModal={setShowModal}
            postTitle={title}
            clickDelete={deleteClick}
          />
        )}
        <section className="overflow-hidden text-gray-700">
          <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
            {progress1 && (
              <div className="w-full bg-gray-200 rounded-full h-6 dark:bg-gray-700 mb-2 relative">
                <div className="absolute w-full text-center text-white">
                  ภาพปกนอก
                </div>
                <div
                  className="h-6 rounded-full"
                  style={{
                    width: `${progress1}%`,
                    backgroundColor: currentColor,
                  }}
                />
              </div>
            )}
            {progress2 && (
              <div className="w-full bg-gray-200 rounded-full h-6 dark:bg-gray-700 mb-2 relative">
                <div className="absolute w-full text-center text-white">
                  ภาพปกใน
                </div>
                <div
                  className="h-6 rounded-full"
                  style={{
                    width: `${progress2}%`,
                    backgroundColor: currentColor,
                  }}
                />
              </div>
            )}
            {progress3 && (
              <div className="w-full bg-gray-200 rounded-full h-6 dark:bg-gray-700 mb-2 relative">
                <div className="absolute w-full text-center text-white">
                  ภาพประกอบ
                </div>
                <div
                  className="h-6 rounded-full"
                  style={{
                    width: `${progress3}%`,
                    backgroundColor: currentColor,
                  }}
                />
              </div>
            )}
            <div className="mb-6 flex justify-between items-center">
              <h1 className="font-semibold text-2xl">Update Post</h1>
              <div className="flex flex-row-reverse">
                <div
                  className="mx-1 fixed right-[80px] top-[120px] z-10"
                  onClick={updateClick}
                >
                  <Button
                    icon={<IoIosCreate className="mr-1" />}
                    color="white"
                    bgColor="#48bb78"
                    text={<span>Update</span>}
                    borderRadius="10px"
                  />
                </div>
                <div
                  className="mx-1 fixed right-[185px] top-[120px] z-10"
                  onClick={() => setShowModal(true)}
                >
                  <Button
                    icon={<IoMdTrash className="mr-1" />}
                    color="white"
                    bgColor="#f56565"
                    text={<span>Delete</span>}
                    borderRadius="10px"
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-wrap -m-1 md:-m-2 justify-center">
              <div className="flex flex-wrap w-full lg:w-1/2 self-end">
                <div className="inline-block overflow-hidden mb-2 w-full p-1">
                  <div className="relative flex overflow-hidden justify-center items-center w-full z-[1] rounded-lg">
                    <img
                      className="min-w-full min-h-full z-[1]"
                      src={post.img}
                    />
                    <div className="absolute w-full h-full top-0 flex items-start justify-end flex-col text-white pb-[10] pl-[10] z-[2] ">
                      <div
                        className="w-full"
                        style={{ backgroundColor: currentColor }}
                      >
                        <span className="text-lg font-bold m-2 block text-center">
                          ภาพปก
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap w-full lg:w-1/2 self-end">
                <div className="inline-block overflow-hidden mb-2 w-full p-1">
                  <div className="relative flex overflow-hidden justify-center items-center w-full z-[1] rounded-lg">
                    <img
                      className="min-w-full min-h-full z-[1]"
                      src={post.cover}
                    />
                    <div className="absolute w-full h-full top-0 flex items-start justify-end flex-col text-white pb-[10] pl-[10] z-[2] ">
                      <div
                        className="w-full"
                        style={{ backgroundColor: currentColor }}
                      >
                        <span className="text-lg font-bold m-2 block text-center">
                          ภาพปกใน
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap w-full">
                <div className="flex items-center mb-5 w-full">
                  <label
                    htmlFor="title"
                    className="w-20 inline-block text-right mr-4 text-gray-500"
                  >
                    ชื่อโปรเจค
                  </label>
                  <input
                    name="title"
                    id="title"
                    type="text"
                    placeholder="Enter Title"
                    className="border-b-2 flex-1 py-2 placeholder-gray-300 outline-none focus:border-green-400"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                  />
                  <div onClick={updateTitle}>
                    <Button
                      icon={<AiFillEdit className="mr-1" />}
                      color="white"
                      bgColor={currentColor}
                      text={<span>บันทึก</span>}
                      borderRadius="10px"
                    />
                  </div>
                </div>
                <div className="flex justify-center w-full lg:w-1/3">
                  <div className="mb-3 flex items-center">
                    <input
                      className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      type="file"
                      name="coverFile"
                      id="coverFile"
                      accept=".jpeg,.jpg,.png"
                      onChange={(e) => setCover(e.target.files[0])}
                      required
                    />
                    <div onClick={updateCover}>
                      <Button
                        icon={<AiFillEdit className="mr-1" />}
                        color="white"
                        bgColor={currentColor}
                        text={<span>ภาพปก</span>}
                        borderRadius="10px"
                        width="[120px]"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex justify-center w-full lg:w-1/3">
                  <div className="mb-3 flex items-center">
                    <input
                      className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      type="file"
                      name="coverInFile"
                      id="coverInFile"
                      accept=".jpeg,.jpg,.png"
                      onChange={(e) => setCoverIn(e.target.files[0])}
                      required
                    />
                    <div onClick={updateCoverIn}>
                      <Button
                        icon={<AiFillEdit className="mr-1" />}
                        color="white"
                        bgColor={currentColor}
                        text={<span>ภาพปกใน</span>}
                        borderRadius="10px"
                        width="[120px]"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex justify-center w-full lg:w-1/3">
                  {/* <div className="mb-3 flex items-center"> */}
                  <div className="mb-3 flex items-center">
                    <input
                      className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      type="file"
                      name="imgFiles"
                      id="imgFiles"
                      accept=".jpeg,.jpg,.png"
                      multiple
                      onChange={(e) => setImages(e.target.files)}
                      required
                    />
                    <div onClick={updateImageExt}>
                      <Button
                        icon={<AiFillEdit className="mr-1" />}
                        color="white"
                        bgColor={currentColor}
                        text={<span>ภาพประกอบ</span>}
                        borderRadius="10px"
                        width="[180px]"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* <Gallery photos={imgSrc} /> */}
              {imgArr.length > 0 && (
                <SortableGallery
                  items={imgArr}
                  onSortEnd={onSortEnd}
                  axis="xy"
                />
              )}
            </div>
          </div>
        </section>
      </div>
    );
  } else {
    return null;
  }
};

export default Read;
