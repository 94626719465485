/* eslint no-unused-vars: off */
import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { FiSettings } from 'react-icons/fi';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

import { Navbar, Footer, Sidebar, ThemeSettings } from './components';
import { Dashboard, Login, Register, Create, Read } from './pages';
import './App.css';

import { useStateContext } from './contexts/ContextProvider';
import PrivateRoute from './utils/PrivateRoute';

const App = () => {
  const {
    setCurrentColor,
    setCurrentMode,
    currentMode,
    activeMenu,
    currentColor,
    themeSettings,
    setThemeSettings,
  } = useStateContext();

  useEffect(() => {
    const currentThemeColor = localStorage.getItem('colorMode');
    const currentThemeMode = localStorage.getItem('themeMode');
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, []);

  return (
    <div className={currentMode === 'Dark' ? 'dark' : ''}>
      <BrowserRouter>
        <Routes>
          {/* authen  */}
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
        </Routes>
        <div className="flex relative dark:bg-main-dark-bg">
          <div className="fixed right-4 bottom-4" style={{ zIndex: '1000' }}>
            <TooltipComponent content="Settings" position="Top">
              <button
                type="button"
                onClick={() => setThemeSettings(true)}
                style={{ background: currentColor, borderRadius: '50%' }}
                className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
              >
                <FiSettings />
              </button>
            </TooltipComponent>
          </div>
          {activeMenu ? (
            <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white ">
              <Sidebar />
            </div>
          ) : (
            <div className="w-0 dark:bg-secondary-dark-bg">
              <Sidebar />
            </div>
          )}
          <div
            className={
              activeMenu
                ? 'dark:bg-main-dark-bg  bg-main-bg min-h-screen md:ml-72 w-full  '
                : 'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 '
            }
          >
            <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
              <Navbar />
            </div>
            <div>
              {themeSettings && <ThemeSettings />}
              <Routes>
                <Route path="/" element={<PrivateRoute />}>
                  {/* dashboard  */}
                  <Route index element={<Dashboard isWIP={false} />} exact />
                  <Route path="/posts" element={<Dashboard isWIP={false} />} exact />
                  <Route path="/post/:id" element={<Read isWIP={false} />} />
                  <Route path="/create" element={<Create isWIP={false} />} exact />
                  <Route path="/wip" element={<Dashboard isWIP />} exact />
                  <Route path="/wip/:id" element={<Read isWIP />} />
                  <Route path="/wcreate" element={<Create isWIP />} exact />
                  {/* <Route path="/ecommerce" element={<Ecommerce />} exact /> */}

                  {/* pages  */}
                  {/* <Route path="/orders" element={<Orders />} exact />
                  <Route path="/employees" element={<Employees />} exact />
                  <Route path="/customers" element={<Customers />} exact /> */}

                  {/* apps  */}
                  {/* <Route path="/kanban" element={<Kanban />} exact />
                  <Route path="/editor" element={<Editor />} exact />
                  <Route path="/calendar" element={<Calendar />} exact />
                  <Route path="/color-picker" element={<ColorPicker />} exact /> */}

                  {/* charts  */}
                  {/* <Route path="/line" element={<Line />} exact />
                  <Route path="/area" element={<Area />} exact />
                  <Route path="/bar" element={<Bar />} exact />
                  <Route path="/pie" element={<Pie />} exact />
                  <Route path="/financial" element={<Financial />} exact /> */}
                  {/* <Route
                    path="/color-mapping"
                    element={<ColorMapping />}
                    exact
                  />
                  <Route path="/pyramid" element={<Pyramid />} exact />
                  <Route path="/stacked" element={<Stacked />} exact /> */}
                </Route>
              </Routes>
            </div>
            <Footer />
          </div>
        </div>
      </BrowserRouter>
    </div>
  );
};

export default App;
